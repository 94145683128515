import { graphql } from "gatsby"
import React from "react"
import ArticleTaster from "../../components/article_taster"
import Layout from "../../components/layout"

const BlogIndex = ({ data }) => {
    return (<Layout pageTitle="blogs" description="All of my blog posts. Read about some fascinating stuff here! :^)">
        {data.allFile.edges.map(({ node: { name, childMdx: { frontmatter: { title, date, tags }, excerpt, id } } }, i) => {
            return (<ArticleTaster key={i} title={title}
                id={id}
                date={date}
                excerpt={excerpt} link={name} tags={tags} />)
        })}
    </Layout>
    )
}

export default BlogIndex

export const query = graphql`
query {
  allFile(
    filter: {sourceInstanceName: {eq: "blog"}, childMdx: {body: {ne: null}, frontmatter: {draft: {ne: true}}}}
    sort: {fields: childMdx___frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        id
        name
        childMdx {
id
          frontmatter {
            title
            date
tags
          }
          excerpt
        }
      }
    }
  }
}
`
